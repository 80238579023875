.SpinRotate-div{
    width: 3em;
    height: 3em;
    background-color: rgba(240, 248, 255, 0);
    border-radius: 50%;
    border-top: 0.2em solid #4C545B;
    border-left: 0.2em solid #4C545B;
    border-bottom: 0.2em solid #4C545B;
    animation: rotation 2s infinite linear;
    padding: 0.2em;
    margin: 0.5em;
}


@keyframes rotation{
    0% {
        transform: rotate(0);
    }
    50%{
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg)
    }
    100%{ 
        transform: rotate(360deg)
    }
}

 