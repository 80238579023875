.Navbar-div{
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-around;
    width: 100%

}
.Navbar-link{
    font-size: 1.3 rem;
    width: fit-content;
    height: 2em;
    border-radius: 0.2em;
    background-color: rgba(35, 60, 222, 0.825);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.7em 2.5em;
    cursor: pointer;
}
.Navbar-link:hover{
    background-color: rgba(35, 60, 222, 0.948);
}
.Navbar-link:active{
    box-shadow: 0 0 0.5em inset ;
}

.Navbar-h3{
    font-weight: 700;
    color:#f0f8ff; 
}