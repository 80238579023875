.App {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: cornsilk;
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
  background-image: url("/public/fondo.jpg");
  background-size:cover ;
  background-repeat: no-repeat;
}
.Home-div{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 2em;
}
.Home-header{ 
  width: 100vw;
  padding: 1em;
  background-color: rgba(245, 245, 220, 0.721);
}
