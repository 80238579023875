.Register-form {
    min-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    background-color: rgba(65, 65, 244, 0.496);
    border-radius: 0.5em;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.734);
    padding: 2em;
    font-size: 1.2rem;
    color: aliceblue;
    margin-top: 10%;

}

.Register-div--mainFormData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(124, 25, 151, 0.466);
    padding: 1.5em;
    border-radius: 0.5em;
}

.Register-label {
    font-size: 1rem;
    font-weight: 600;
    margin: 0.4em;
}

.Register-input {
    padding: 0.5em;
    border: 0.1em solid rgba(0, 0, 0, 0.458);
    border-radius: 0.5em;
}

.Register-input::placeholder {
    color: rgba(245, 245, 245, 0.67)
}

.Register-errorValidation {
    font-size: 1rem;
    font-weight: 500;
    color: rgb(224, 13, 13);
    background-color: rgba(0, 0, 0, 0.493);
    padding: 0.5em;
    border-radius: 0.5em;
}

.Login-button-form {
    background-color: rgba(255, 235, 205, 0.578);
    padding: 0.5em;
    border-radius: 0.5em;
    box-shadow: 0 0 0.3em black;
}

.Login-button-form:active {
    box-shadow: 0 0 0.5em inset black;
}

.Login-Link {
    font-size: 0.8rem;
}

.Login-strong {
    color: black;
    font-size: 1rem;
    font-weight: 600;
}