@charset "UTF-8";
/*->  codificas en utf-8 que hace que todos los caracteres del documento se interpreten correctamente independientemente del idioma o el sistema en el que se visualice.*/

:root {
  --black-chocolate: #141204ff;
  --negro: #393939;
  /* Tipografía */
  --tipo-principal: Poppins, sans-serif;
  --tipo-secundaria: sans-serif;
}

/* Las adaptamos al modo oscuro */
@media (prefers-color-scheme: dark) {
  :root {
    --blanco: #ececec;
  }
}

/* Desactivamos los animations en el caso de que el usuario haya configurado el modo sin animation */
@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation: none !important;
    animation: none !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}

/* Reseteamos los margin y paddings de todas las etiquetas */
* {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: baseline;
}

/* Evitamos problemas con las imagenes */
img,
picture,
video,
iframe,
figure {
  max-width: 100%;
  width: 100%;
  display: block;
  /* opcional */
  -o-object-fit: cover;
  object-fit: cover;
  /* opcional */
  -o-object-position: center center;
  object-position: center center;
}

/* Reseteamos los enlaces para funcionar como cajas... */
a {
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
}

/* ... excepto los que se encuentran en párrafos */
p a {
  display: inline;
}

/* Quitamos los puntos de los <li> */
li {
  list-style-type: none;
}

/* Configuramos anclas suaves */
html {
  scroll-behavior: smooth;
}

/* Desactivamos estilos por defecto de las principales etiquetas de texto */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
strong,
blockquote,
i,
b,
u,
em {
  font-size: 1em;
  font-weight: inherit;
  font-style: inherit;
  text-decoration: none;
  color: inherit;
}

/* Evitamos problemas con los pseudoelementos de quotes -> comillas en citas */
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/* Configuramos el texto que seleccionamos */
::-moz-selection {
  background-color: var(--negro);
  color: var(--blanco);
}

::selection {
  background-color: var(--negro);
  color: var(--blanco);
}

/* Nivelamos problemas de tipografías y colocación de formularios */
form,
input,
textarea,
select,
option,
button,
label {
  font-family: inherit;
  font-size: inherit;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  background-color: transparent;
  color: inherit;
  display: block;
  /* opcional */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Reseteamos las tablas */
table,
tr,
td {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Evitamos problemas con los SVG */
svg {
  width: 100%;
  display: block;
  fill: currentColor;
}

/* Configuramos la tipografía para toda la web */
body {
  min-height: 100vh;
  font-size: 100%;
  font-family: var(--tipo-principal);
  color: var(--black-chocolate);
  line-height: 1.2em;
  /* opcional */
  -webkit-hyphens: auto;
  /* hace que si se corta una palabra se ponga un guion automaticamente en chrome*/
  -ms-hyphens: auto;
  /*igual pero en Edge*/
  hyphens: auto;
  /* opcional */
  /* font-smooth: always; */

  /*reduce bordes dentados y mejora la calidad de las letras*/
  /* opcional */
  -webkit-font-smoothing: antialiased;
  /* opcional */
  -moz-osx-font-smoothing: grayscale;
}
